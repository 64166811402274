@import "../../styles/variables.scss";

.button {
  background: $vav-blue-1;
  color: $white;
  width: 100%;
  padding: $spacing-12;
  font-weight: $font-weight-medium;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:focus {
    outline: none;
    background: lighten($vav-blue-1, 5%);
  }

  &:disabled {
    color: $grey-1;
    background: $grey-5;
    cursor: not-allowed;
  }
}

.outline,
.grey {
  padding: 11px $spacing-12;
  border: 2px solid $vav-blue-1;
  color: $vav-blue-1;
  background: $white;

  &:focus {
    background: $grey-7;
  }
}

.grey {
  border-color: $grey-4;
  color: $grey-1;
}

.inputContainer {
  position: relative;
}

.hiddenInput {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  pointer-events: none;
  top: 32px;
  left: 12px;
}

.radio,
.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding-left: $spacing-32;

  & span {
    transition: all 0.2s ease-in-out;
    user-select: none;
  }

  .hiddenInput:checked ~ & span {
    color: $vav-blue-1;
  }
}

.radio::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #000;

  .hiddenInput:checked ~ & {
    border-color: $vav-blue-1;
  }
}

.radio::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: $vav-blue-1;
  opacity: 0;
  transform: translateY(-50%) scale(0.5);

  .hiddenInput:checked ~ & {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

.checkbox {
  .icon {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;

    & svg {
      color: #fff;
    }
  }
}

.checkbox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #000;

  .hiddenInput:checked ~ & {
    border-color: $vav-blue-1;
  }

  .hiddenInput:focus ~ & {
    border-color: lighten($vav-blue-1, 10%);
  }
}

.checkbox::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: $vav-blue-1;

  opacity: 0;
  transform: translateY(-50%) scale(0.5);

  .hiddenInput:checked ~ & {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

.greenBackground::before {
  background: $green-light;
}

.greenBackground.checkbox {
  & svg {
    color: $green-light;
  }
}

.textInputContainer,
.textAreaContainer {
  position: relative;
  padding: $spacing-12 0;
}

.textInput {
  display: block;
  width: 100%;
  padding: $spacing-8;
  border: 2px solid $vav-blue-1;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $black;
  }
}

.textInputLabel {
  position: absolute;
  top: -5px;
  left: 0;
  transform: translateY(0);
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  color: $vav-blue-1;

  .textInput:placeholder-shown ~ & {
    font-size: $font-size-m;
    top: 50%;
    left: $spacing-8;
    transform: translateY(-50%);
    color: $vav-blue-1;
  }
}

.textArea {
  display: block;
  width: 100%;
  height: 100px;
  padding: $spacing-8;
  border: 2px solid $grey-2;

  resize: none;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $vav-blue-1;
  }
}

.textAreaLabel {
  position: absolute;
  top: -3px;
  left: 0px;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  color: $vav-blue-1;

  .textArea:placeholder-shown ~ & {
    font-size: $font-size-m;
    top: 22px;
    left: 10px;
    color: $grey-2;
  }
}

.errorMessage {
  padding-bottom: 0.25rem;

  span {
    font-size: $font-size-s;
    color: red;
  }
}

.showPlaceholder {
  &::placeholder {
    opacity: 1;
    color: $grey-2;
  }
}

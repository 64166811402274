@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import "./variables.scss";
@import "./animations.scss";

/*
  Resets annoying things */

* {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border-radius: 0;
}

html {
  text-decoration-skip-ink: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
}

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

/*
  Removes touch input lag from tappable things */

a,
button,
input,
textarea,
select,
label,
canvas {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
  Global styles */

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
}

body {
  position: relative;
  font-weight: $font-weight-normal;
  font-family: "Work Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: $font-size-m;
  line-height: 1.6;
  background: $grey-6;
}

/* React collapse */
.ReactCollapse {
  &--collapse {
    transition: height 250ms ease;
  }

  &--feed {
    transition: height 250ms ease-out;
    min-height: 60px;
    position: relative;
  }
}

/*
 * Alignment
 */
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

// Colours
$black: #000000;
$white: #ffffff;

$grey-1: #37393b;
$grey-2: #5b6060;
$grey-3: #9598a0;
$grey-4: #c0c5d1;
$grey-5: #d9e0e8;
$grey-6: #e5eaf1;
$grey-7: #f7f8fb;

$green: #b6d3bb;
$green-light: #def0e1;

$blue: #9fc7e1;
$blue-light: #eff5f8;
$blue-dark: #3f6b88;

$yellow: #fdd976;
$yellow-light: #fff4d6;

$orange: #e69d28;
$orange-light: #ffe8c4;

$teal: #115b68;
$red: #e35050;

$vav-green: #c9e9e6;
$vav-green-login: #add6c9;

$vav-blue-1: #004d8f;
$vav-blue-2: #add6ff;
$vav-blue-3: #d1e6fa;
$vav-blue-4: #ebf5ff;

$vav-red-1: #f05a60;
$vav-red-2: #fbd0d2;
$vav-red-3: #f9e7e8;

// Spacing
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-48: 48px;
$spacing-64: 64px;
$spacing-128: 128px;

// Typography
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 24px;

// Dimensions
$header-height: 50px;
$nav-height: 60px;

@import "../../styles/variables.scss";

.challengeContainer {
  overflow: hidden;
  margin: $spacing-16 0;
  user-select: none;
}

.header {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-16 0;
}

.icon {
  position: absolute;
  z-index: -1;
  left: -15px;
  top: -15px;

  .spring &,
  .autumn & {
    left: -3px;
  }
}

.winter,
.spring,
.summer,
.autumn,
.daily {
  margin: $spacing-16;
  border-radius: 8px;
  background: $vav-blue-4;
}

// .winter {
//   background: $blue-light;
// }

// .daily,
// .spring {
//   background: $green-light;
// }

// .autumn {
//   background: $orange-light;
// }

// .summer {
//   background: $yellow-light;
// }

.textCenter {
  text-align: center;
  margin-top: $spacing-24;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}

.spinner {
  max-width: 100px;
}

.textContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

@import "../../styles/variables.scss";

.tabs {
  position: relative;
  display: flex;
  height: 100%;
}

.tab {
  flex: 1 1 0%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $grey-2;
}

.label {
  position: relative;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 5px;
    background: transparent;
    border-radius: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transition: all 0.2s ease-in-out;
  }
}

.active {
  color: $black;

  &::after {
    transform: translateX(-50%) scaleX(1);
  }

  &.teal::after {
    background: $teal;
  }

  &.yellow::after {
    background: $yellow;
  }

  &.orange::after {
    background: $orange;
  }

  &.green::after {
    background: $green;
  }

  &.blue::after {
    background: $vav-blue-1;
  }

  &.red::after {
    background: $vav-red-1;
  }
}

.divider {
  position: absolute;
  width: 1px;
  height: 22px;
  background: $grey-5;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

@import "../../styles/variables.scss";

.tag {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  border-radius: 256px;
  min-width: 30px;
  min-height: 20px;
}

.teal {
  background: $teal;
  color: $white;
}

.yellow {
  background: $yellow;
  color: $black;
}

.blue {
  background: $vav-blue-2;
  color: $vav-blue-1;
}

.red {
  background: $vav-red-2;
  color: $vav-red-1;
}

.darkRed {
  background: $vav-red-1;
  color: $white;
}

.lightRed {
  background: $vav-red-3;
  color: $black;
}

.darkBlue {
  background: $vav-blue-1;
  color: $white;
}

.orange {
  background: $orange;
  color: $black;
}

.grey {
  background: $grey-6;
  color: $black;
}

.small {
  padding: 2px $spacing-4;

  .icon {
    width: 20px;

    & > svg {
      height: 16px;
    }
  }

  .value,
  .label {
    padding: 2px $spacing-4 1px $spacing-4;
    font-size: $font-size-s;
  }
}

.large {
  padding: $spacing-4;

  .icon {
    width: 30px;

    & > svg {
      height: 18px;
    }
  }

  .value,
  .label {
    padding: 0 $spacing-8 0 $spacing-4;
    font-size: $font-size-m;
  }
}

.center {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.icon {
  display: flex;
  justify-content: center;

  & > svg {
    width: auto;
  }
}

.value,
.label {
  white-space: nowrap;
}

.value {
  font-weight: $font-weight-medium;
}

@import "../../styles/variables.scss";

.feed {
  display: flex;
  margin: $spacing-32 0 $spacing-16 0;
  padding: 0 $spacing-16;
}

.timeline {
  position: relative;
  flex: 0 0 $spacing-24;
  border-left: 2px solid $grey-4;
  margin-bottom: $spacing-16;
  margin-left: $spacing-4;
  transform: translateY(5px);
}

.timelineGradient {
  position: absolute;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba($white, 0), $white);
  left: -5px;
  bottom: 0;
}

.items {
  flex: 1 1 auto;
  max-width: 93%;

  & img {
    height: auto;
    max-width: 100%;
  }
}

.item:not(:last-of-type) {
  padding-bottom: $spacing-16;
  margin-bottom: $spacing-16;
  border-bottom: 1px solid $grey-5;
}

.date {
  position: relative;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $grey-2;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid $white;
    border-radius: 50%;
    background: $grey-4;
    top: 4px;
    left: -29px;
  }
}

.top {
  padding: $spacing-12 0;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: $font-size-l;
  font-weight: $font-weight-medium;
  padding-right: $spacing-16;
}

.contentGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(rgba($white, 0), $white);
  opacity: 0;
  transition: opacity 250ms ease-out;

  .collapsed & {
    opacity: 1;
  }
}

.buttons {
  padding-top: $spacing-16;
  display: flex;
  justify-content: flex-end;
}

.toggle {
  display: flex;
  align-items: center;
  color: $vav-red-1;
  margin-right: $spacing-4;
  cursor: pointer;
  user-select: none;

  & > svg {
    margin-left: $spacing-4;
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }
}

.rotated > svg {
  transform: rotate(180deg);
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}

.spinner {
  max-width: 100px;
}

.textCenter {
  text-align: center;
  margin-top: $spacing-24;
}

@import "../../styles/variables.scss";

.navBar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: $nav-height;
  border-top: 1px solid $grey-4;
  background: $white;

  @media (min-width: 768px) {
    width: 720px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 15px 15px;
  }
}

.container {
  display: flex;
  width: 100%;
  max-width: 414px;
}

.itemContainer {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
}

.item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: $grey-2;
  margin: 0 $spacing-4;
}

.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    transform: scale(0.8);
    transition: all 0.15s ease-in-out;
  }

  & > svg {
    transition: all 0.15s ease-in-out;
  }
}

.label {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  transition: all 0.15s ease-in-out;
  user-select: none;
}

.active {
  color: $black;

  .iconContainer::before {
    transform: none;
  }

  &.green .iconContainer {
    &::before {
      background: $vav-green;
    }
  }

  &.blue .iconContainer {
    &::before {
      background: $vav-blue-2;
    }
  }

  &.red .iconContainer {
    &::before {
      background: $vav-red-1;
    }

    & > svg {
      color: $white;
    }
  }

  &.white .iconContainer {
    &::before {
      background: $grey-4;
    }
  }
}

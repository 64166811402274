@import "../../styles/variables.scss";

$container-padding: $spacing-24;

.screenContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: $vav-green-login;
}

.background {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 20%;
  width: auto;
}

.loginContainer {
  position: fixed;
  padding: $container-padding;
  padding-top: $spacing-8;
  width: 500px;
  max-width: calc(100% - 40px);
  max-height: calc(80% - 40px);
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.viewContainer {
  position: relative;
}

.logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 50px;
    width: auto;
  }
}

.flexY {
  flex-direction: column;

  p {
    padding-top: 5px;
  }
}

.submit {
  margin-top: $spacing-24;
}

.switchViewLabel {
  position: relative;
  text-align: center;
  margin: $container-padding 0 $container-padding 0;
  width: calc(100% + #{$container-padding} + #{$container-padding});
  height: 1px;
  transform: translateX(-#{$container-padding});
  background: $grey-4;

  & > span {
    position: absolute;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    color: $grey-2;
    background: $white;
    padding: $spacing-4 $spacing-12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}

.goToView {
  margin-top: $spacing-16;

  span {
    text-decoration: none;
    color: $grey-1;

    &:hover {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.checkboxContainer {
  margin: $spacing-12 0;
}

.viewTerms {
  color: $vav-blue-1;
  text-decoration: underline;
  cursor: pointer;
}

.complexLabel {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $vav-blue-1;
}

@keyframes popIn {
  from {
    transform: translateX(-50%) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
}

.errMsg {
  color: red;
  font-size: $font-size-s;
}

@import "../../styles/variables.scss";

.header {
  position: fixed;
  width: 100%;
  height: $header-height * 3;
  transition: all 300ms ease-in-out;
}

.green {
  background: $vav-green;
}

.blue {
  background: $vav-blue-1;
}

.red {
  background: $vav-red-1;
}

.white {
  background: transparent;
}

.container {
  position: relative;
  width: 100%;
  height: $header-height;
}

.heading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & img {
    height: 40px;
    width: auto;
  }
}

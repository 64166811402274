@import "../../styles/variables.scss";

.challenge {
  position: relative;
  padding-top: $spacing-16;
  transition: all 200ms ease-in-out;
  overflow: hidden;
}

.winter,
.spring,
.summer,
.autumn,
.daily {
  &.open {
    background: $vav-blue-3;
  }
  .clickable::before {
    right: 0px;
    border-radius: 5px 0 0 5px;
  }
  .content {
    border-bottom: 1px solid $vav-blue-2;
  }
  .hidingOverlay {
    background: $vav-blue-4;
  }
}

// .winter {
//   .content {
//     border-bottom: 1px solid $blue;
//   }
//   .hidingOverlay {
//     background: $blue-light;
//   }
// }

// .spring,
// .daily {
//   .content {
//     border-bottom: 1px solid $green;
//   }
//   .hidingOverlay {
//     background: $green-light;
//   }
// }

// .summer {
//   .content {
//     border-bottom: 1px solid $yellow;
//   }
//   .hidingOverlay {
//     background: $yellow-light;
//   }
// }

// .autumn {
//   .content {
//     border-bottom: 1px solid $orange;
//   }
//   .hidingOverlay {
//     background: $orange-light;
//   }
// }

.hidden {
  display: none;
}

.standard {
  padding-left: $spacing-16;
  padding-right: $spacing-16;
}

.openIndicator {
  margin-right: $spacing-4;
  width: 24px;
  height: 24px;
  transition: all 200ms ease-in-out;
}

.container {
  display: flex;
}

.icon {
  flex: 0 0 60px;
  padding-left: $spacing-12;
  padding-top: $spacing-4;
}

.content {
  flex: 1 1 auto;
  max-width: 82%;
  padding-bottom: $spacing-16;
  border-bottom: 1px solid $grey-4;

  .challenge:last-of-type & {
    border-bottom: none;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.clickable {
  position: relative;
  z-index: 2;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
  }

  &:active::before {
    background: rgba(0, 5, 10, 0.05);
  }
}

.title {
  font-size: $font-size-m;
  font-weight: $font-weight-medium;
  line-height: 24px;
  user-select: none;
}

.description {
  padding-top: $spacing-16;
  padding-right: $spacing-8;
}

.description img {
  height: auto;
  max-width: 100%;
  min-width: auto;
  width: auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacing-16;
}

.button {
  flex-basis: 50%;
  padding-right: $spacing-16;
}

.animateButtonOuter {
  animation: buttonAppear 250ms ease-in-out backwards;
}

.animateButtonInner {
  animation-name: fontSize, fadeTextIn;
  animation-duration: 50ms, 150ms;
  animation-delay: 250ms, 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

.buttonOutline,
.buttonSolid {
  width: 100%;
  border: 2px solid $vav-blue-1;
  height: 40px;
  color: $vav-blue-1;
  font-size: $font-size-m;
  font-weight: $font-weight-medium;
  background: $white;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $grey-1;
    background: $grey-5;
    border-color: $grey-5;
    cursor: not-allowed;
  }
}

.buttonSolid {
  background: $vav-red-1;
  color: $white;
}

.open {
  background-color: $grey-7;

  .openIndicator {
    transform: rotate(180deg);
  }
}

.accepted,
.open.accepted {
  background-color: $vav-red-3;
}

.completionStage {
  padding-right: $spacing-16;
}

.completionQuestion {
  font-size: $font-size-l;
  font-weight: $font-weight-medium;
  padding: $spacing-16 0 0 0;
}
.inputContainer {
  position: relative;
  padding: $spacing-12 0;
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $vav-red-1;
  color: $white;
  animation: completeAppear 300ms ease-in-out backwards;
}

.completedInner {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 40px;
    height: 40px;
  }
}

.plusPoints {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  animation: pointsAppear 300ms 600ms ease-in-out backwards;
}

.hidingOverlay {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $white;
  animation: completeAppear 300ms ease-in-out backwards;
}

.openIndicatorContainer {
  display: flex;
  align-items: center;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
}
.spinner {
  max-width: 100px;
}
.errMsg {
  color: red;
  font-size: $font-size-s;
}

@keyframes open {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes buttonAppear {
  from {
    opacity: 0;
    flex-basis: 0%;
  }

  to {
    opacity: 1;
    flex-basis: 50%;
  }
}

@keyframes fontSize {
  from {
    font-size: 0px;
  }

  to {
    font-size: $font-size-m;
  }
}

@keyframes fadeTextIn {
  from {
    color: transparent;
  }

  to {
    color: $white;
  }
}

@keyframes completeAppear {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translate(0);
  }
}

@keyframes pointsAppear {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Main routes (.route-animation) */

.route-animation-enter {
  opacity: 0;
}

.route-animation-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.route-animation-exit {
  opacity: 1;
}

.route-animation-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

/* From left to right tab (.left-to-right-animation) */

.left-to-right-animation-enter {
  transform: translateX(100%);
}

.left-to-right-animation-enter-active {
  transform: translateX(0);
  transition: transform 400ms ease-in-out;
}

.left-to-right-animation-exit {
  transform: translateX(0);
}

.left-to-right-animation-exit-active {
  transform: translateX(-100%);
  transition: transform 400ms ease-in-out;
}

/* From right to left tab (.right-to-left-animation) */

.right-to-left-animation-enter {
  transform: translateX(-100%);
}

.right-to-left-animation-enter-active {
  transform: translateX(0);
  transition: transform 400ms ease-in-out;
}

.right-to-left-animation-exit {
  transform: translateX(0);
}

.right-to-left-animation-exit-active {
  transform: translateX(100%);
  transition: transform 400ms ease-in-out;
}

/* Header text (.header-animation) */

.header-animation-enter {
  transform: translateY(5px);
  opacity: 0;
}

.header-animation-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 150ms 150ms ease-in-out, transform 150ms 150ms ease-in-out;
}

.header-animation-exit {
  transform: translateY(0);
  opacity: 1;
}

.header-animation-exit-active {
  transform: translateY(-5px);
  opacity: 0;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}

/* Login (.login-animation) */

.login-animation-enter {
  transform: translateX(-50%) scale(1.05);
  opacity: 0;
}

.login-animation-enter-active {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  transition: opacity 150ms 150ms ease-in-out, transform 150ms 150ms ease-in-out;
}

.login-animation-exit {
  transform: translateX(-50%) scale(1);
  opacity: 1;
}

.login-animation-exit-active {
  transform: translateX(-50%) scale(0.95);
  opacity: 0;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
